import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import Header from "./components/Header";
import { fetchAuthMe, selectIsAuth } from "./store/slices/auth";
import "react-toastify/dist/ReactToastify.css";
import useRoutes from "./routes";
import Loader from "./components/Loader";
import { BsDownload } from "react-icons/bs";
import Footer from "./components/Footer/Footer";
import axios from "axios";

function App() {
  const routes = useRoutes();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const status = useSelector((state) => state.courses.status);
  const statusAuth = useSelector((state) => state.auth.status);
  const ref = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token) {
      dispatch(fetchAuthMe(token));
    }

    // Функция для отправки запроса к серверу
    const sendRequest = () => {
      axios
        .get("https://alippe-backend-v1.onrender.com/api/getVersion")
        .then((response) => {
          console.log("RENDER:", response.data);
          // Обработка ответа
        })
        .catch((error) => {
          console.error("Error:", error);
          // Обработка ошибки
        });
    };

    // Немедленно отправляем запрос при загрузке компонента
    sendRequest();

    // Устанавливаем интервал для отправки запроса каждые 40 секунд
    const intervalId = setInterval(() => {
      sendRequest();
    }, 700000); // 40 секунд

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  // const scrollClick = () => {
  //   console.log('scroll');
  //   ref.current?.scrollIntoView({ behavior: "smooth" });
  // };

  return (
    <div className="App">
      <Header />
      {routes}
      {/* <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/course/:id" element={<DetailPage />} />
        <Route path="/video/:id" element={<VideoPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes> */}
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div style={{ position: "fixed", right: 0, bottom: 30 }}></div>
    </div>
  );
}

export default App;
